import { ExtraOptions, Routes } from '@angular/router';

import { ClientRoute, InternalRoute, RedirectGuard, XkpGuard } from '@yuno/admin/core/guards';
import { AuthGuard, userRoleResolver, xkpMemberResolver } from '@yuno/angular-auth';

import { RoutingComponent } from './components/routing/routing.component';

export const routes: Routes = [
	{
		path: '',
		component: RoutingComponent,
		canActivate: [AuthGuard],
		resolve: {
			xkp: xkpMemberResolver,
			userRole: userRoleResolver
		},
		children: [
			// redirects the users to the correct url, Clients or Internal
			{ path: '', pathMatch: 'full', children: [], canActivate: [RedirectGuard] },
			{
				path: ClientRoute,
				loadChildren: () =>
					import('./views/clients/_router/client.routing').then(m => m.ClientRouterModule)
			},
			{
				path: InternalRoute,
				canActivate: [XkpGuard],
				loadChildren: () =>
					import('./views/internal/_router/internal.routing').then(
						m => m.InternalRouterModule
					)
			},
			{ path: '*', pathMatch: 'full', children: [], canActivate: [RedirectGuard] }
		]
	},
	{
		path: 'auth',
		loadChildren: () => import('@yuno/angular-auth').then(m => m.AuthRoutes)
	},
	{ path: '*', pathMatch: 'full', redirectTo: 'auth/login' },
	{ path: '**', redirectTo: '' }
];

export const routingConfiguration: ExtraOptions = {
	paramsInheritanceStrategy: 'always'
};
