import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { headerAppId } from '@yuno/shared/helpers';

export const HeaderInterceptor: HttpInterceptorFn = (req, next) => {
	const router = inject(Router);
	const state: RouterStateSnapshot = router.routerState.snapshot;

	const routeData = getAllRouteData(state.root);
	const appId = routeData?.['app']?._id as string;

	if (!appId) {
		return next(req);
	}

	const cloned = req.clone({
		setHeaders: {
			[headerAppId]: appId
		}
	});

	return next(cloned);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAllRouteData = (snapshot: ActivatedRouteSnapshot): { [key: string]: any } => {
	let data = snapshot.data;

	snapshot.children.forEach(childSnapshot => {
		data = { ...data, ...getAllRouteData(childSnapshot) };
	});

	return data;
};
