import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	PreloadAllModules,
	provideRouter,
	withPreloading,
	withRouterConfig
} from '@angular/router';
import { DefaultOptions } from '@apollo/client/core';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { provideTransloco } from '@ngneat/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { isProduction } from 'apps/project-atlas/src/app/helpers';
import { ColorPickerModule } from 'ngx-color-picker';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

import {
	AdminCoreModule,
	COOKIE_DOMAIN,
	ENVIRONMENT,
	clearCurrentAppState
} from '@yuno/admin/core';
import { HeaderInterceptor } from '@yuno/admin/core/interceptors';
import { provideAuthConfig } from '@yuno/angular-auth';
import { GraphqlAngularModule } from '@yuno/angular-graphql';
import { ApiModule } from '@yuno/angular/api';
import { provideTextfieldConfig } from '@yuno/angular/textfield';
import { LanguagesArray } from '@yuno/api/interface';

import { environment } from '../environments/environment';
import { routes, routingConfiguration } from './app.routes';
import { TranslocoHttpLoader } from './modules/transloco-root.module';

const getDomainWithoutSubdomain = (url: Location) => {
	const urlParts = new URL(url.toString()).hostname.split('.');
	return urlParts.slice(urlParts.length - 2, urlParts.length).join('.');
};

const defaultApolloOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore'
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	},
	mutate: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all'
	}
};

if (isProduction) {
	enableProdMode();
}

export const appConfig: ApplicationConfig = {
	providers: [
		{
			provide: ENVIRONMENT,
			useValue: environment || {}
		},
		{
			provide: COOKIE_DOMAIN,
			useValue: getDomainWithoutSubdomain(window.location)
		},
		//
		// NGRX
		provideStore([], {
			metaReducers: [clearCurrentAppState],
			runtimeChecks: {
				strictActionImmutability: true,
				strictStateImmutability: true
			}
		}),
		provideEffects(),
		provideStoreDevtools({
			connectInZone: true,
			maxAge: 25, // Retains last 25 states
			logOnly: !isDevMode(), // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
			trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
			traceLimit: 75 // maximum stack trace frames to be stored (in case trace option was provided as true)
		}),
		//
		provideAuthConfig({ redirect: ['./'], authUrl: environment['yuno-api'] }),
		provideTextfieldConfig(),
		provideHotToastConfig({
			position: 'bottom-right',
			style: {
				border: '1px solid #171c23', // yuno-gray-800
				padding: '0.75rem 1rem',
				color: '#b0b5bc', // yuno-gray-200
				backgroundColor: '#2e3746' // yuno-gray-600
			}
		}),
		provideRouter(
			routes,
			withRouterConfig(routingConfiguration),
			withPreloading(PreloadAllModules)
		),
		provideAnimations(),
		provideHttpClient(withInterceptors([HeaderInterceptor]), withInterceptorsFromDi()),
		provideTransloco({
			config: {
				availableLangs: LanguagesArray,
				defaultLang: 'nl',
				fallbackLang: 'nl',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: !isDevMode()
			},
			loader: TranslocoHttpLoader
		}),
		importProvidersFrom(
			AdminCoreModule.withConfig({
				cookieDomain: getDomainWithoutSubdomain(window.location)
			}),
			GraphqlAngularModule.withConfig({
				uri: environment['yuno-graphql'],
				defaultOptions: defaultApolloOptions
			}),
			ApiModule.forRoot({
				url: environment['yuno-api']
			}),

			MonacoEditorModule.forRoot(),
			ColorPickerModule
		)
	]
};
